*{
    padding: 0;
    margin: 0;
}

/*========================= Button=========================*/
.NavigateTicketPage {
    color: white;
    background-color: #52734D;
    width: 25%;
    min-width: 150px;
    font-size: 20px;
    font-weight: 200;
    padding: 5px;
    border-radius: 10px;
}
.HugeButton {
    color: white;
    background-color: #52734D;
    width: 40%;
    min-width: 250px;
    min-height: 80px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
}
.NavigateHome {
    background-color: white;
    font-size: 18px;
    font-weight: 500;
    border: none;
    padding: 10px;
}

.NavigateHome:hover, .NavigateHome:disabled{
    background-color: rgb(199, 225, 187);
}
.IntroButton{
    background-color: #91C788;
    color: white;
    min-width: 150px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 300;
    border: none;
}

/*========================= Cards =========================*/

.TicketCard {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    background-color: white;
    color: #52734D;
    border-radius: 10px;
    border: 1px solid #52734D;
    padding: 20px;
    font-size: 20px;
    min-width: 200px;
    transition: transform 0.5s, box-shadow 0.5s;
}
.changingNum{
    background-color: #91C788;
    padding: 10px;
    border-radius: 5px;
}

.Picked{
    border: 5px solid #52734D;
}

.BasketCard {
    display: flex;
    flex-direction: column;
    justify-self: center;
    background-color: #FEFFDE;
    border: 1px solid #52734D;
    border-radius: 10px;
    color: #52734D;
    font-size: 24px;
    padding: 10px;
}
.TicketCard:hover {
    transform: translate(0, -10px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.adding {
    background-color: #FEFFDE;
    border-radius: 10px;
    padding: 10px;
}
.ExcludeButton {
    margin-top: 10px;
    background-color: #DDFFBC;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;
}

.HistoryCard{
    background-color: #DDFFBC;
    color: #52734D;
    font-size: 24px;
    border: 1px solid #52734D;
    border-radius: 10px;
    padding: 10px;
}