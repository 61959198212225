*{
    padding: 0;
    margin: 0;
}

.banner{
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-color: #91C788;
    align-items: center;
    justify-content: center;
    color: white
}
.bannerIntro{
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-color: #DDFFBC;
    align-items: center;
    justify-content: center;
    color: #52734D
}

@keyframes popup {
    0% {
        opacity: 0.3;
        bottom: 10vh;
        display: block;
    }
    25% {
        opacity: 1;
        bottom: 13vh;
        display: block;
    }
    100%{
        opacity: 1;
        bottom: 13vh;
        display: none;
    }
}

@keyframes fade {
    from {
        display: block;
        opacity: 1;
    }
    to{
        display: none;
        opacity: 0.3;
    }
    
}

.overlay{
    position: fixed;
    bottom: 10vh;
    left: 10vw;
    color: white;
    background-color: black;
    padding: 10px;
    animation-name: popup;
    animation-duration: 2s;
    animation-fill-mode: both;
}

.overlaycontainer{
    height: 10vh;
}

.overlayNotification{
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 20px;
    border-radius: 10px;
    width: 100%;
    min-width: 250px;
    padding: 10px;
    gap: 10px;
    overflow-y: auto;
}

/* .overlayNotification button{
    padding: 10px;
    background-color: #52734D;
    color: white;
    border-radius: 7px;
} */

.overlayBackground{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
}



/*=============================== Root ============================*/
.navbar{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #72916e75;
}
.footer{
    display: flex;
    gap: 20px;
    background-color: #52734D;
    color: white;
    padding: 20px;
}
.PersonalLayout{
    display: flex;
    gap: 10px;
}
.PersonalLayout button{
    background-color: white;
    border-radius: 10px;
    border: none;
}
.PersonalLayout button:hover{
    background-color: rgb(199, 225, 187);
}
/*============================== Basket ============================*/
.Basket{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.SumMoney {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    min-height: 50px;
    background-color: white;
    position: fixed;
    bottom: 0vh;
    color: black;
}

.Payment{
    background-color: #52734D;
    color: white;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
}

/*============================= PickTicket ===========================*/

.daypicker{
    font-size: 20px;
    display: flex;
    flex-direction: column;
    background-color: #91C788;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 30px;
}

.dayconfirm{
    background-color: #DDFFBC;
    font-size: 18px;
    border-radius: 7px;
    padding: 10px;
}

.Shop {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 20px;
}

/*====================================== Profile ===================================================*/
.History {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/*===========================Chat room=====================*/

.messageContsent{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.messageContreceived{
    width: 100%;
    display: flex;
    flex-direction: row;
}


.message{
    margin: 15px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    min-width: 250px;
    max-width: 600px;
    gap: 10px;
}
.displayName{
    position: relative;
    top: -5px;
    right: -150px;
}
.message img{
    border-radius: 10px;
}
.sent{
    background-color: #52734D;
    color: white;
    flex-direction: row-reverse;
    justify-content: end;
    justify-self: flex-end;
    align-items: start;
}
.received{
    background-color: #DDFFBC;
    color: #52734D;
    justify-content: start;
    align-items: start;
}
.SendForm{
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.SendForm input{
    border-radius: 10px;
    height: 40px;
    width: 80%;
}

.SendForm button{
    background-color: #52734D;
    color: white;
    min-width: 80px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 300;
    border: none;
}